<template>
  <div class="welcome">
    <h2 class="welcome__heading">Félicitations <strong>{{newUser.firstname + ' ' + newUser.lastname}}</strong></h2>
    <p class="welcome__paragraph">
      Vous avez soumis avec succès une demande d'inscription, Notre équipe l'examinera et vous reviendra
       avec une réponse dans les plus brefs délais.
    </p>
    <router-link :to="{name:'Home'}" class="welcome__next">
      Aller vers la page d'accueil
    </router-link>
  </div>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed, onBeforeMount} from "vue";
  import {useRouter} from "vue-router";
  const router = useRouter();
  const store = useStore();
  const newUser = computed(() => store.state.users.newUser);
  if(!newUser.value) {
    router.push({name:'Login'});
  }
</script>

<style lang="scss" scoped>
  .welcome{
    @apply bg-white rounded-md
    px-7 py-10 md:px-20 lg:px-[118px] pt-[118px] pb-[58px];
    &__heading{
      @apply text-lg leading-6
      mb-8;
    }
    &__paragraph{
      @apply text-lg leading-9
      mb-13;
    }
    &__next{
      @apply inline-block text-center 
      py-2 w-full md:max-w-[329px] 
    text-white bg-primary
      rounded-md;
    }
  }
</style>